<template>
	<!-- container -->
	<div class="container">
		<!-- contents area -->
		<div class="body-contents">
			<!-- Title -->
			<h2 class="">회원탈퇴</h2>
			<div class="section-wrap center">
				<div class="section">
					<div class="contents">
						<h3 class="mb-16">회원탈퇴 안내</h3>
						<p class="mb-16">지금까지 이용해주셔서 감사합니다. 탈퇴 신청에 앞서 아래의 유의사항을 확인해주세요.</p>
						<div class="highlight bullet-list">
							<ul class="">
								<li>현재 사용중인 계정 정보는 회원 탈퇴 후 복구가 불가합니다.</li>
								<li>회원 탈퇴 시 등록한 회사 정보와 연결이 끊기고, 해당 데이터는 복구되지 않습니다.</li>
								<li>
									소셜 로그인 회원의 경우, 모든 정보가 삭제되며 같은 소셜 아이디로 재가입 시 신규 회원으로 가입됩니다.
								</li>
								<li>
									해당 탈퇴는 다우오피스 고객포털 회원탈퇴이며, 다우오피스 계정을 탈퇴하시려면 관리자에게 문의하세요.
								</li>
								<li>주문 대기 중인 상품이 있을 경우, 자동으로 주문이 취소됩니다.</li>
							</ul>
						</div>
					</div>
				</div>
				<div class="section">
					<div class="contents">
						<h3 class="mb-16">
							본인인증
							<em class="text-caution">(필수)</em>
						</h3>
						<div class="group row mb-16 gap8 align-middle">
							<!--							<button class="line">본인인증</button>-->
							<IdentityVerificationButton
								:className="'line'"
								:title="'본인인증'"
								v-on:complete="handleComplete"
								code-type="secession"
							/>
							<span class="body-2-r">정보를 안전하게 보호하기 위해 회원탈퇴 시에는 본인인증이 필요합니다.</span>
						</div>
						<div class="highlight mb-16">
							혹시 메일 수신 및 개인 정보 등에 대한 불편으로 회원탈퇴를 원하신다면 정보 수정에서 세부 사항을 수정하고
							약관별로 동의를 처리할 수 있습니다.
						</div>
						<p v-if="verificationText" class="text-detail" :class="[!isVerificationCompleted ? 'invalid' : 'valid']">
							<i class="ic-16" :class="[!isVerificationCompleted ? 'ic-invalid' : 'ic-valid']"></i
							>{{ verificationText }}
						</p>
					</div>
				</div>
				<div class="section">
					<div class="contents">
						<h3 class="mb-16">
							회원 탈퇴 사유
							<em class="text-caution">(필수)</em>
						</h3>
						<div class="group col3">
							<div class="group-rdo">
								<input
									type="radio"
									id="testRadio01"
									name="testOption"
									value="TerminationDO"
									v-model="secessionReason"
								/>
								<label for="testRadio01" class="label">다우 오피스 해지</label>
							</div>
							<div class="group-rdo">
								<input
									type="radio"
									id="testRadio02"
									name="testOption"
									value="ChangeManager"
									v-model="secessionReason"
								/>
								<label for="testRadio02" class="label">퇴직/이직/담당 변경</label>
							</div>
							<div class="group-rdo">
								<input type="radio" id="testRadio03" name="testOption" value="Frequency" v-model="secessionReason" />
								<label for="testRadio03" class="label">사용 빈도 낮음</label>
							</div>
						</div>
						<div class="group col3">
							<div class="group-rdo">
								<input type="radio" id="testRadio04" name="testOption" value="Complication" v-model="secessionReason" />
								<label for="testRadio04" class="label">서비스 이용 복잡/불편</label>
							</div>
							<div class="group-rdo">
								<input type="radio" id="testRadio05" name="testOption" value="Rejoin" v-model="secessionReason" />
								<label for="testRadio05" class="label">아이디 변경/재가입 목적</label>
							</div>
							<div class="group-rdo">
								<input type="radio" id="testRadio06" name="testOption" value="Etc" v-model="secessionReason" />
								<label for="testRadio06" class="label">기타</label>
							</div>
						</div>
						<p v-if="reasonText" class="text-detail" :class="[!checkReason ? 'invalid' : 'valid']">
							<i class="ic-16" :class="[!checkReason ? 'ic-invalid' : 'ic-valid']"></i>{{ reasonText }}
						</p>
					</div>
				</div>
				<div class="section">
					<div class="contents">
						<h3 class="mb-16">
							개선사항 입력
							<em class="text-additional">(선택)</em>
						</h3>
						<div class="textarea">
							<textarea
								maxlength="100"
								placeholder="회원 탈퇴 사유에 대한 상세 의견을 작성해 주세요.
고객님의 소중한 의견으로 더 나은 서비스를 제공할 수 있도록 노력하겠습니다. (최대 100자)"
							></textarea>
						</div>
					</div>
				</div>
				<div class="section">
					<div class="contents">
						<div class="group-chk">
							<input type="checkbox" id="testCheckbox" v-model="rejectAgree" />
							<label for="testCheckbox" class="label text-main">
								위 내용을 모두 확인하였으며, 탈퇴에 동의합니다.
								<em class="text-caution">(필수)</em>
							</label>
						</div>
					</div>
				</div>
				<div class="btn-area">
					<button class="line large" @click="handleCancel">취소</button>
					<button class="solid large" v-on="{ click: checkAllRequired ? handleRejectUser : handleConfirm }">
						탈퇴하기
					</button>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { findId, rejectUser } from '@/api/login';
import IdentityVerificationButton from '@/components/login/IdentityVerificationButton';
import mixinsAlert from '@/components/common/mixinsAlert';
import { checkIdentity } from '@/components/login/mixinsFindUser';
import { SECESSION_COMPLETE } from '@/temlplates';

export default {
	components: { IdentityVerificationButton },
	name: 'Secession',
	mixins: [mixinsAlert, checkIdentity],
	data() {
		const { email } = this.$store.state.login.currentUser;
		return {
			isVerificationCompleted: false,
			verificationText: '',
			checkReason: false,
			reasonText: '',
			secessionReason: '',
			rejectAgree: false,
			etcReason: '',
			email: email,
		};
	},
	watch: {
		secessionReason() {
			this.checkReason = true;
			this.reasonText = '';
		},
	},
	computed: {
		checkAllRequired() {
			return this.isVerificationCompleted && this.checkReason && this.rejectAgree;
		},
	},
	methods: {
		async handleComplete(data) {
			let userData = {
				name: data.name,
				ci: data.ci,
				phoneNumber: data.phoneNumber,
				birthDay: data.birthday,
			};

			try {
				const response = await findId({
					name: userData.name,
					ci: userData.ci,
					phoneNumber: userData.phoneNumber,
					birthDay: userData.birthDay,
					loginId: this.$store.getters['getLoginId'],
				});

				if (response.data.length > 1) {
					this.$toasted.show('회원 탈퇴 본인인증에 실패했습니다.');
					this.isVerificationCompleted = false;
					this.verificationText = '본인인증을 완료하지 못했습니다.';
					return;
				}
			} catch (e) {
				// this.moveToStep(1);
				console.log(e);
				this.$toasted.show('회원 탈퇴 본인인증에 실패했습니다.');
				this.isVerificationCompleted = false;
				this.verificationText = '본인인증을 완료하지 못했습니다.';
				return;
			}

			// await this.findUserByIdentityVerification(userData, this.loginId, (resp) => {
			this.isVerificationCompleted = true;
			this.verificationText = '본인인증을 완료하였습니다.';
			// });
		},
		handleCancel() {
			this.$router.push('/profile');
		},
		handleConfirm() {
			if (!this.isVerificationCompleted) {
				this.verificationText = '본인인증이 완료되지 않았습니다.';
			}
			if (!this.checkReason) {
				this.reasonText = '회원 탈퇴 사유가 선택되지 않았습니다.';
			}
		},
		async handleRejectUser() {
			// TODO: Reason 전달
			this.alert({
				title: '회원 탈퇴',
				contents: '정말 탈퇴하시겠습니까?',
				buttons: [
					{
						callback: () => {
							rejectUser()
								.then(async (response) => {
									this.goodByeModal();
									await this.$router.push('/login');
								})
								.catch((error) => {
									// TODO-MJ: 탈퇴시 회사정보가 있는 경우, 해당 회사 관리자 계정이 하나인지 확인
									// TODO-MJ: "회사의 관리자 계정이 하나만 존재해 탈퇴가 불가능합니다. 초대를 통해 다른 관리자를 지정해주세요."
									this.alert(error.data.errorMessage);
								});
						},
					},
					{},
				],
			});
		},
		goodByeModal() {
			this.alert({
				title: '회원 탈퇴 완료',
				contents: SECESSION_COMPLETE,
			});
		},
	},
};
</script>

<style scoped>
@import '../../../src/assets/css/common.css';
</style>
